import React from "react";
import styled from "@emotion/styled";

import Container from "../container";

const Title = styled.div<any>`
    font-size: 36px;
    font-family: ${props => props.theme.headerFontFamily};
    margin-bottom: 20px;
`;

const Content = styled.div<any>`
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 100px;
`;

export interface IScopeOfWorkProps {
    scopeOfWork: Array<string>
}

export default function Approach(props: IScopeOfWorkProps): JSX.Element {
    const {scopeOfWork} = props;

    return (
        <Container>
            <Title>
                Scope Of Work
            </Title>
            <Content>
                {scopeOfWork.join(", ")}
            </Content>
        </Container>
    );
}