import React from "react";
import {Link} from "gatsby";
import styled from "@emotion/styled";

import Container from "../container";

const BackLinkContainer = styled.div<any>`
    margin: 80px 0 80px;
    
    a {
        color: ${props => props.theme.offPrimaryColor};
    }
`;

const ClientLogo = styled.div<any>`
    margin-bottom: 40px;

    > img {
        max-height: 50px;
        margin-bottom: 0;
    }
`;
const CaseStudyIntro = styled.p<any>`
    font-size: 18px;
    line-height: 1.4;
`;

export interface IPageHeaderProps {
    clientLogoURL: string;
    caseStudyIntro: string;
    clientName: string;
}

export default function PageHeader(props: IPageHeaderProps): JSX.Element {
    const {
        clientLogoURL,
        caseStudyIntro,
        clientName
    } = props;

    return (
        <Container>
            <BackLinkContainer>
                <Link to="/case-studies">Back to Case Studies</Link>
            </BackLinkContainer>
            <ClientLogo>
                <img src={clientLogoURL} alt={`${clientName} Logo`}/>
            </ClientLogo>
            <CaseStudyIntro>
                {caseStudyIntro}
            </CaseStudyIntro>
        </Container>
    );
}