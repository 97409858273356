import React from "react";
import styled from "@emotion/styled";

import Container from "../container";

const Title = styled.div<any>`
    font-size: 36px;
    font-family: ${props => props.theme.headerFontFamily};
    margin-bottom: 20px;
    margin-top: 80px;
`;

const Content = styled.div<any>`
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 80px;
    white-space: pre-wrap;
`;

export interface IApproachProps {
    caseStudyApproach: string;
}

export default function Approach(props: IApproachProps): JSX.Element {
    const {caseStudyApproach} = props;

    return (
        <Container>
            <Title>
                Our Approach
            </Title>
            <Content>
                {caseStudyApproach}
            </Content>
        </Container>
    );
}