import React from "react";
import {Link} from "gatsby";
import styled from "@emotion/styled";

import { IService } from "../../utils/types";
import Container from "../container";
import {md} from "../../utils/breakpoints";

const CardBase = styled.div`
    padding: 10px;
    background: #fff;
    margin: 80px 0;
`

const CardContent = styled.div<any>`
    border: 1px solid ${props => props.theme.primaryColor};
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const ChallengeContent = styled.div<any>`
    flex: 1 1 70%;
    font-size: 20px;
    padding: 20px;
    line-height: 1.4;
    white-space: pre-wrap;
`;

const ServicesContent = styled.div<any>`
    flex: 1 1 30%;
    padding: 20px;
    border-left: 1px solid ${props => props.theme.primaryColor};

    @media (max-width: ${md}px) {
        border-left-color: transparent;
    }
`;

const ServicesList = styled.ul<any>`
    margin: 0;
    padding: 0;
    list-style: none;
`;

const ServicesListItem = styled.li<any>`
    display: block;
    padding: 0;
    margin: 0 0 10px;
    min-width: 200px;

    a {
        color: ${props => props.theme.offPrimaryColor};
        font-size: 20px;
    }
`;

const SectionHeader = styled.div<any>`
    font-family: ${props => props.theme.headerFontFamily};
    font-size: 36px;
    margin-bottom: 20px;
`;

export interface IChallengeCardProps {
    caseStudyChallenge: string;
    services: Array<IService>;
}

export default function ChallengeCard(props: IChallengeCardProps): JSX.Element {
    const {
        caseStudyChallenge,
        services
    } = props;

    return (
        <Container>
            <CardBase>
                <CardContent>
                    <ChallengeContent>
                        <SectionHeader>
                            The Challenge
                        </SectionHeader>
                        {caseStudyChallenge}
                    </ChallengeContent>
                    <ServicesContent>
                        <SectionHeader>
                            Services
                        </SectionHeader>
                        <ServicesList>
                            {services.map(s => (
                                <ServicesListItem key={s.slug}>
                                    <Link to="/services">
                                        {s.name}
                                    </Link>
                                </ServicesListItem>
                            ))}
                        </ServicesList>
                    </ServicesContent>
                </CardContent>
            </CardBase>
        </Container>
    );
}