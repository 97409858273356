import React from "react";
import {graphql} from "gatsby";

import { IService, IQuote } from "../utils/types";
import Layout from "../components/layout/layout";
import PageHeader from "../components/caseStudy/pageHeader";
import ChallengeCard from "../components/caseStudy/challengeCard";
import Preview from "../components/caseStudy/preview";
import Approach from "../components/caseStudy/approach";
import Outcome from "../components/caseStudy/outcome";
import Quotes from "../components/caseStudy/quotes";
import ScopeOfWork from "../components/caseStudy/scopeOfWork";

export default function CaseStudy({ data }) {
    const {
        caseStudyTitle,
        caseStudySlug,
        caseStudyScopeOfWork,
        caseStudyApproach,
        caseStudyChallenge,
        caseStudyOutcome,
        caseStudyIntro,
        caseStudyPreview,
        client,
        services,
        quotes
    } = data.contentfulCaseStudy;

    const quoteMarkURL = data.quoteFile.publicURL;

    const cleanServices: Array<IService> = services.map(s => ({
        name: s.serviceName,
        slug: s.serviceSlug,
        definition: s.serviceDefinition,
        shortDescription: s.serviceShortDescription,
        tactics: s.serviceTactics
    }));

    const cleanQuotes: Array<IQuote> =  quotes ? quotes.map(q => ({
        content: q.quoteContent.quoteContent,
        person: {
            name: q.person.name,
            title: q.person.title
        }
    })) : [];

    return (
        <Layout title={caseStudyTitle}>
            <PageHeader
                clientName={client.clientName}
                clientLogoURL={client.clientLogo.file.url}
                caseStudyIntro={caseStudyIntro.caseStudyIntro} />
            <ChallengeCard
                caseStudyChallenge={caseStudyChallenge.caseStudyChallenge} 
                services={cleanServices}/>
            <Preview caseStudyPreview={caseStudyPreview.caseStudyPreview} />
            <Approach caseStudyApproach={caseStudyApproach.caseStudyApproach} />
            <Outcome caseStudyOutcome={caseStudyOutcome.caseStudyOutcome} />
            {cleanQuotes.length && (
                <Quotes quotes={cleanQuotes} quoteMarkURL={quoteMarkURL} />
            )}
            <ScopeOfWork scopeOfWork={caseStudyScopeOfWork} />
        </Layout>
    );
};

export const query = graphql`
    query($slug: String!) {
        contentfulCaseStudy(caseStudySlug: {eq: $slug}) {
            caseStudyTitle
            caseStudySlug
            caseStudyScopeOfWork
            caseStudyApproach {
                caseStudyApproach
            }
            caseStudyChallenge {
                caseStudyChallenge
            }
            caseStudyOutcome {
                caseStudyOutcome
            }
            caseStudyIntro {
                caseStudyIntro
            }
            caseStudyPreview {
                caseStudyPreview
            }
            client {
                clientLogo {
                    file {
                        url
                    }
                }
                clientName
            }
            services {
                serviceDefinition
                serviceName
                serviceShortDescription
                serviceSlug
                serviceTactics
            }
            quotes {
                person {
                    title
                    name
                }
                quoteContent {
                    quoteContent
                }
            }
        }

        quoteFile: file(relativePath: {eq: "quote-mark-blue.svg"}) {
            publicURL
        }
    }
`;