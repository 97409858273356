import React from "react";
import styled from "@emotion/styled";

import Container from "../container";
import {md} from "../../utils/breakpoints";

const PreviewText = styled.div`
    font-size: 48px;
    line-height: 1.4;

    @media (max-width: ${md}px) {
        font-size: 24px;
    }
`;

const Seperator = styled.div<any>`
    height: 1px;
    width: 140px;
    background: ${props => props.theme.borderColor};
    margin: 60px 0;

    @media (max-width: ${md}px) {
        margin: 60px auto;
    }
`;

export interface IPreviewProps {
    caseStudyPreview: string;
}

export default function PageHeader(props: IPreviewProps): JSX.Element {
    const {
        caseStudyPreview
    } = props;

    return (
        <Container>
            <Seperator />
            <PreviewText>
                {caseStudyPreview}
            </PreviewText>
            <Seperator />
        </Container>
    );
}