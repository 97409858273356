import React from "react";
import styled from "@emotion/styled";

import Container from "../container";
import { IQuote } from "../../utils/types";

const CardBase = styled.div`
    max-width: 800px;
    background: #fff;
    padding: 10px;
    margin: 0px auto 80px;
`;

const CardContent = styled.div<any>`
    border: 1px solid ${props => props.theme.primaryColor};
    padding: 60px;
`;

const QuoteContent = styled.div<any>`
    font-size: 18px;
    line-height: 1.4;
    position: relative;
    white-space: pre-wrap;

    &:before {
        content: '';
        position: absolute;
        font-family: ${props => props.theme.headerFontFamily};
        width: 24px;
        height: 24px;
        left: -40px;
        top: -10px;
        background-image: url(${props => props.quoteMarkURL});
        background-size: 24px 24px;
    }
`;

const QuotePerson = styled.div<any>`
    font-style: italic;
    font-size: 18px;
    line-height: 1.4;
    margin-top: 40px;
`;

export interface IApproachProps {
    quotes: Array<IQuote>;
    quoteMarkURL: string;
}

export default function Outcome(props: IApproachProps): JSX.Element {
    const {quotes, quoteMarkURL} = props;

    const [quote] = quotes;

    return (
        <Container>
            <CardBase>
                <CardContent>
                    <QuoteContent quoteMarkURL={quoteMarkURL}>
                        {quote.content}
                    </QuoteContent>
                    <QuotePerson>
                        {quote.person.name}, {quote.person.title}
                    </QuotePerson>
                </CardContent>
            </CardBase>
        </Container>
    );
}